import { observer } from 'mobx-react';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, ListGroup, Form, Collapse, Modal } from 'react-bootstrap';
import AuthService from "../../services/auth.service";
import groupStore from "./GroupStore";
import PDFViewer from "./PDFViewer"

const baseURL = process.env.REACT_APP_API_BASE_URL_AUTH;

const EventDetailsPage = observer(() => {


    const { id } = useParams();
    const [trigger, setTrigger] = useState(0);
    const [event, setEvent] = useState();
    // const [pdfs, setPdfs] = useState([
    //     { title: "Event Brochure", url: "https://example.com/brochure.pdf" },
    //     { title: "Workshop Agenda", url: "https://example.com/agenda.pdf" },
    //   ]);

    const [pdfsrc, setPdfsrc] =useState(null);
      const [pdfs, setPdfs] = useState([ ]);
    const [info, setInfo] = useState({ title: "" });
    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);  // State to control collapsible area
    const user = AuthService.getCurrentUser();


  const location = useLocation();
 // const event = location.state || null
  console.log("****   "+ id+ "---------"+JSON.stringify(location.state ));

  const eventDetails = {
    title: event?.groupName,
    groupId: "event?.groupId",
    intro: "Join us for an engaging day of math and science activities, workshops, and fun learning opportunities for all ages!",
    time: "Saturday, December 15th, 2024, from 10:00 AM to 4:00 PM",
    location: "Community Center, 123 Learning Lane, Education City",
    materials: [
      { name: "Event Brochure", link: "https://example.com/brochure.pdf" },
      { name: "Workshop Agenda", link: "https://example.com/agenda.pdf" },
    ],
    videos: [
      { title: "Introduction to STEM Activities", link: "https://youtu.be/example1" },
      { title: "Highlights from Last Year's Event", link: "https://youtu.be/example2" },
    ],
    contactInfo: {
      name: "Event Coordinator",
      email: "coordinator@example.com",
      phone: "(123) 456-7890",
    },
    registrationLink: "https://example.com/register",
    faq: [
      {
        question: "Who can attend this event?",
        answer: "The event is open to everyone interested in math and science, regardless of age or background.",
      },
      {
        question: "Is there a registration fee?",
        answer: "No, the event is completely free of charge!",
      },
    ],
  };

  useEffect(() => {
         
    const fetchPosts = async () => {
    
        try {         
        
            await groupStore.getGroupById(id); // Fetch questions from API
            await groupStore.getPdfList(id);

          const { data, loading, error } = groupStore;
          setEvent(data.group); // Set questions to state
          setPdfs(data.pdfList);
        } catch (error) {
          console.error("Failed to fetch questions:", error);
        }
  
        //setQuestions(questionsData);
      };
      fetchPosts();
  }, [trigger, groupStore]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Store the selected file
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const handleUpdateInfo = async (e) => {
    e.preventDefault();
    
    if (!file) {
        alert("Please select a PDF file!");
        return;
      }
  
      const formData = new FormData();
      formData.append("title", info.title); // Append other form data
      formData.append("file", file); // Append the selected file groupId
      formData.append("groupId", id);
    await groupStore.postPdf(formData);
    // alert('Information updated successfully!');
  };

  const getPdfSrc = async (id) => {
    await groupStore.getPdfFile(id);
    const { data, loading, error } = groupStore;
    const file = new Blob([data.pdffile], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    setPdfsrc(fileURL);
  };
  
  return (
<>
    

    <div className="container my-5">

    {user?.authUser.roles.includes("10")||user?.authUser.roles.includes("30") ?
        <div className="bg-light p-3">
     <Button
      onClick={() => setOpen(!open)}
      aria-controls="collapsible-area"
      aria-expanded={open}
      variant="primary"
    >
      {open ? "Hide adding new pdf" : "Show adding new pdf"}
    </Button>
    <Collapse in={open}>
      <div id="collapsible-area" className="mt-3">
        <h4>upload new pdf</h4>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>标题</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              name="title"
              value={info.title}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formFile">
        <Form.Label>上传 PDF</Form.Label>
        <Form.Control type="file" accept="application/pdf" onChange={handleFileChange} />
      </Form.Group>
          
          
          <br></br>
          <Button variant="success" className="mt-3" onClick={handleUpdateInfo}>
            保存
          </Button>
        </Form>
        <Form>

        </Form>
      </div>
    </Collapse>
  </div> :null}

      <div className="text-center mb-4">
        <h1 className="display-4">{event?.groupName}</h1>
        <p className="lead">{event?.descb}</p>
      </div>

      <div className="mb-4">
        <h2>活动细节</h2>
        <p><strong>时间:</strong> {event?.meetDateStr}</p>
        <p><strong>地点:</strong> {event?.location}</p>
      </div>

      <div className="mb-4">
        <h2>资源</h2>
        <ul className="list-group">
          {pdfs.map((pdf, index) => (
            <li key={index} className="list-group-item">
              <a href="#" onClick={() => getPdfSrc(pdf.id)} className="register-link">
                        {pdf.title}
                      </a>
            </li>
          )) }
        </ul>
      </div>

     {pdfsrc ? <PDFViewer src ={pdfsrc}/>: ""}
   {/*   <div className="mb-4">
        <h2>Videos</h2>
        <ul className="list-group">
          {eventDetails.videos.map((video, index) => (
            <li key={index} className="list-group-item">
              <a href={video.link} target="_blank" rel="noopener noreferrer">{video.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="mb-4">
        <h2>Contact Information</h2>
        <p><strong>Contact Name:</strong> {eventDetails.contactInfo.name}</p>
        <p><strong>Email:</strong> <a href={`mailto:${eventDetails.contactInfo.email}`}>{eventDetails.contactInfo.email}</a></p>
        <p><strong>Phone:</strong> {eventDetails.contactInfo.phone}</p>
      </div>

      <div className="mb-4 text-center">
        <h2>Registration</h2>
        <a href={eventDetails.registrationLink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Register for the Event</a>
      </div>*/}

     {/*} <div className="mb-4">
        <h2>Frequently Asked Questions</h2>
        <div className="accordion" id="faqAccordion">
          {eventDetails.faq.map((item, index) => (
            <div key={index} className="accordion-item">
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {item.question}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>*/}
    </div>
    </>
  );
});

export default EventDetailsPage;
