import React from 'react';
import './ContactPage.css'; // Import CSS file for styling


const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1 className="contact-title">Contact Us</h1>
      <div className="contact-info">
        <p><strong>Email:</strong> info@tristrands.org  </p>
        <p><strong>Phone:</strong> +1 (848) 667-4421 </p>
      </div>
    </div>
  );
};

export default ContactPage;
