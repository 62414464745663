import axios from "axios";
import authHeader from "./auth-header";

//const API_PUB_URL = 'http://localhost:8090/api/pub/';
//const API_RES_URL = 'http://localhost:8090/api/res/';

//const API_PUB_URL = 'http://34.130.190.39/res/api/pub/';
//const API_RES_URL = 'http://34.130.190.39/res/api/res/';

const baseURL = process.env.REACT_APP_API_BASE_URL_RES;

const API_RES_URL = baseURL + "/api/res/" ;
const API_PUB_URL = baseURL + "/api/pub/"; 



const getPdfIdList = async (groupId)=>{
  return await axios.get(API_RES_URL + "getpdfs/"+groupId,  { headers: authHeader() });
}


const getPdfById = async (id)=>{
  return await axios.get(API_RES_URL + "getpdf/"+id,  { headers: authHeader(),  responseType: "arraybuffer", });
}

const postPdfFile = async (postData) => {
  try {
      const response = await axios.post(API_RES_URL + 'pdfupload', postData,    
      { headers: authHeader() });
      console.log('Post image Successful:', response.data);
      return response.data;
  } catch (error) {
      throw new Error(error.message);
  }
};

/*const getGroupById = async (id)=>{
  return await axios.get(API_RES_URL + "group/"+id,  { headers: authHeader() });
}


const getMyGroupList = async ()=>{
  return await axios.get(API_RES_URL + "mygroups",  { headers: authHeader() });
}

const getMyGroupMemberList = async (id)=>{
  return await axios.get(API_RES_URL + "mygroupsmember/"+id,  { headers: authHeader() });
}

const saveNewGroup = (data) => {
  return axios.post(API_RES_URL + "addgroup",
  {
    groupName : data.groupName,
    descb: data.descb,
    groupType: data.groupType,
    location : data.location,
    meetDateStr : data.time,
    organizer : data.organizer,
  } ,    
  { headers: authHeader() });
};

const saveUserGroup = (data) => {
  return axios.post(API_RES_URL + "addtogroup",
  {
    groupId : data.groupId,
  } ,    
  { headers: authHeader() });
};*/


const ResourseService = {
  postPdfFile,
  getPdfById,
  getPdfIdList,
  
};

export default ResourseService;
