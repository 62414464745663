import { observer } from 'mobx-react';
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, ListGroup, Form, Collapse, Modal } from 'react-bootstrap';
import groupStore from "./GroupStore";
import AuthService from "../../services/auth.service";
import './GroupList.css'; // 


const GroupList = observer(() => {
  const [showModal, setShowModal] = useState(false);

 // const user = JSON.parse(localStorage.getItem('user'));

    const [courses, setCourses] = useState([]);
    const [open, setOpen] = useState(false);  // State to control collapsible area
    const [info, setInfo] = useState({ });
    const [trigger, setTrigger] = useState(0);
    const user = AuthService.getCurrentUser();
    const[event, setEvent] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {

     
        const fetchPosts = async () => {
    
          try {
            if(user){
            
              await groupStore.getGroupListById(); // Fetch questions from API

            }else{

              await groupStore.getGroupList(); // Fetch questions from API

            }
            const { data, loading, error } = groupStore;
            console.log(JSON.stringify(data));
            setCourses(data.groupList); // Set questions to state
          } catch (error) {
            console.error("Failed to fetch questions:", error);
          }
    
          //setQuestions(questionsData);
        };
        fetchPosts();
      }, [trigger, groupStore]);

    const registerGroup = async(groupId) =>{

      if(user){

        const formData = { 'groupId': groupId};
        await groupStore.registerGroup(formData);
        setTrigger(prev => prev + 1);

      }else{        
        navigate('/login', { state: "para" });
      }

        

    };

    const editGroup = async(result)=>{

      setInfo({
        id: result.groupId,
        title: result.groupName,
        description:result.descb,
        grouptype: result.groupType,
        time: result.meetDateStr,
        organizer: result.organizer,
        location: result.location
      });
      setOpen(true);

  };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
      };

      const handleUpdateInfo = async () => {
        //setSavedInfo(info); // Save the current input values
        const formData = {'groupId': info.id, 'groupName': info.title,  'descb': info.description, 'groupType':info.grouptype, 'time' : info.time, 
          'organizer': info.organizer, 'location' :info.location, 'status' : info.status };
        await groupStore.postGroup(formData);
        setTrigger(prev => prev + 1);
        setOpen(false);
        setInfo({
          id: '',
          title: '',
          description: '',
          grouptype: '',
          time: '',
          organizer: '',
          location: ''
        });

        // alert('Information updated successfully!');
      };

      const openModal =(data)=>{

        setEvent(data);
        setShowModal(true);

      }

      const handleGoto = (para) => {
        //alert(JSON.stringify(para));
        navigate('/eventdetails/'+para.groupId, { state: para });
      };

      return (
        <>
          <Container>
            <h2 style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "10vh", // Full viewport height
    }}>活动列表</h2>

{user?.authUser.roles.includes("10")||user?.authUser.roles.includes("30") ?
            <div className="bg-light p-3">
         <Button
          onClick={() => setOpen(!open)}
          aria-controls="collapsible-area"
          aria-expanded={open}
          variant="primary"
        >
          {open ? "Hide Crouse creation" : "Show Crouse creation"}
        </Button>
        <Collapse in={open}>
          <div id="collapsible-area" className="mt-3">
            <h4>create new event</h4>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={info.title}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formTime">
                <Form.Label>Time</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter time"
                  name="time"
                  value={info.time}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formLocation">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Location"
                  name="location"
                  value={info.location}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formOrganizer">
                <Form.Label>Organizer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Organizer"
                  name="organizer"
                  value={info.organizer}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formtype">
                <Form.Label>Grouptype</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter groutype"
                  name="grouptype"
                  value={info.grouptype}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formDescription" className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter description"
                  name="description"
                  value={info.description}
                  onChange={handleInputChange}
                />
              </Form.Group>

               {/* Radio Buttons for Status */}
      <Form.Group controlId="formStatus" className="mt-3">
        <Form.Label>Status</Form.Label>
        <div>
          <Form.Check
            type="radio"
            label="Active"
            name="status"
            value="1"
            checked={String(info.status) === "1"}
            onChange={handleInputChange}
          />
          <Form.Check
            type="radio"
            label="Inactive"
            name="status"
            value="0"
            checked={String(info.status) === "0"}
            onChange={handleInputChange}
          />
        </div>
      </Form.Group>
              
              <br></br>
              <Button variant="success" className="mt-3" onClick={handleUpdateInfo}>
                Save Info
              </Button>
            </Form>
          </div>
        </Collapse>
      </div> :null}

      <Row>
      {courses ? courses.map((result, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="study-card">
              <Card.Body>
                <Card.Title><h2 className="event-title">{result.groupName}</h2></Card.Title>
              
                
                 { <Card.Text className="card-text">             
              
              <p><strong>时间:</strong> {result.meetDateStr}</p>
              <p><strong>地点:</strong> {result.location}</p>
              <p><strong>活动类型:</strong> {result.groupType}</p>
              <p><strong>组织者:</strong> {result.organizer}</p> 
              <p><strong>介绍:</strong> {result.descb}</p>           
                
                </Card.Text>}
                </Card.Body>
                {/*<Card.Link href="#">Go to {category.title}</Card.Link>*/}
                <Card.Footer className="text-center">
                <div className="show-more">

                <div>
                        {result.groupRole ?   <a href="#" onClick={() => handleGoto(result)} className="register-link">
                        进入
                      </a>: 
                        String(result.status) === "0"?  "Expired/已结束" :
                        <a href="#" onClick={() => registerGroup(result.groupId)} className="register-link">
                        注册
                      </a> 
                        
                        }
                </div>
                  <a href="#"onClick={() => openModal(result)}>更多</a>
                </div>
                {result?.groupRole==1 ? <a href="#" onClick={() => editGroup(result)} className="register-link">
                        Edit
                      </a>:null}
                </Card.Footer>
              
            </Card>
          </Col>
        )) :null}
      </Row>
          </Container>

          <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h2 className="event-title">{event.groupName}</h2>
        <p><strong>时间:</strong> {event.meetDateStr}</p>
              <p><strong>地点:</strong> {event.location}</p>
              <p><strong>活动类型:</strong> {event.groupType}</p>
              <p><strong>组织者:</strong> {event.organizer}</p> 
              <p><strong>介绍:</strong> {event.descb}</p>  
              <div className="d-flex justify-content-between mt-2">
                        {event.groupRole ? <Button
                            variant="secondary"
                            onClick={() => handleGoto(event)}
                        >
                            进入活动页
                        </Button> : 
                        <Button
                        variant="secondary"
                        onClick={() => registerGroup(event.groupId)}                    >
                        注册 
                       </Button>
                        
                        }
                </div>         
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            关闭
          </Button>
          {/* Save button or other actions */}
        </Modal.Footer>
      </Modal>
        </>
      );


})

export default GroupList