import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "react-bootstrap-submenu/dist/index.css";
import React, { useState, useEffect } from "react";
import EventBus from "../common/EventBus";
import AuthService from "../services/auth.service";
import { Outlet, Link } from "react-router-dom";

const NavBar = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.authUser.roles.includes("50"));
      setShowAdminBoard(user.authUser.roles.includes("0"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container fluid>
        <Navbar.Brand href="/home">
        <img
            height="60"
            className="logoimg"
            alt="  "
          />
          三股绳
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/home">首页</Nav.Link>
              <Nav.Link href="/search">搜寻</Nav.Link>
              <Nav.Link href="/presentation">讲座</Nav.Link>
              <Nav.Link href="/groups">活动</Nav.Link>
              <Nav.Link href="/messageboard">留言</Nav.Link>
              <Nav.Link href="/about">关于</Nav.Link>
              <Nav.Link href="/contact">联系</Nav.Link>
              {/*<NavDropdownMenu title="Dropdown 1" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/editordemo3">Action</NavDropdown.Item>
                <DropdownSubmenu href="#action/3.7" title="Text to show">
                  <NavDropdown.Item href="/editortest">Sub 1</NavDropdown.Item>
                  <DropdownSubmenu href="#action/3.7" title="Text to show">
                    <NavDropdown.Item href="#action/9.1">
                      Sub 2
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </DropdownSubmenu>
  </NavDropdownMenu>*/}
            </Nav>
          </Navbar.Collapse>

          {currentUser ? (
            <Navbar.Collapse className="justify-content-end">
              <Nav>
              <Navbar.Text>
              登录身份: <a href="/profile">{currentUser.authUser.user}</a>
              </Navbar.Text>
              <Navbar.Text>
                <a href="/" onClick={logOut}>
                  {"   "}
                  LogOut
                </a>
              </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          ) : (
            <Navbar.Collapse className="justify-content-end">
              <Nav>
                <Nav.Link href="/login">登录</Nav.Link>
                <Nav.Link href="/register">注册</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
