import { observable, action, makeObservable } from 'mobx';
import groupService from "../../services/group.service";
import resourseService from '../../services/resourse.service';


class GroupStore {
    constructor() {        
        makeObservable(this, {
            data: observable,
            loading: observable,
            error: observable,
            getGroupList: action, 
            getMyGroupList: action,    
            postGroup : action, 
            registerGroup : action,
            getGroupListById : action,
            getGroupById : action,
            getPdfList : action,
            postPdf : action,
           
        });
    }
    
    data = {
        groupList: [],        // problem : null,
        myGroupList: [],
        group :{},
        pdfList : [],
        pdffile: [],
    };

    //data = [];
    loading = true;
    error = null; 
  
    getPdfList = async (id) => {
        this.loading = true;
        try {
            const response  = await resourseService.getPdfIdList(id);    
            this.data.pdfList =  response.data;                 
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    getPdfFile = async (id) => {
        this.loading = true;
        try {
            const response  = await resourseService.getPdfById(id);    
            this.data.pdffile =  response.data;                 
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    getGroupList = async () => {
        this.loading = true;
        try {
            const response  = await groupService.getGroupList();    
            this.data.groupList =  response.data;                 
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    getGroupListById = async () => {
        this.loading = true;
        try {
            const response  = await groupService.getGroupListById();    
            this.data.groupList =  response.data;                 
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    getGroupById = async (id) => {
        this.loading = true;
        try {
            const response  = await groupService.getGroupById(id);    
            this.data.group =  response.data;                 
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };
    
    getMyGroupList = async () => {
        this.loading = true;
        try {
            const response  = await groupService.getMyGroupList();    
            this.data.myGroupList =  response.data;                 
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    postGroup = async (postData) => {
        this.loading = true;
        try {
            const response = await groupService.saveNewGroup(postData);
            //this.data.problemId =  response.data;  
            console.log('Post Successful:');
            // Optionally, you can update the data after posting
            // this.data.push(response.data);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    registerGroup = async (postData) => {
        this.loading = true;
        try {
            const response  = await groupService.saveUserGroup(postData);  
            console.log('Post Successful:');                  
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };  

    postPdf  = async (postData) => {
        this.loading = true;
        try {
            console.log("!@!@!@!@!@!@!@!@");
            const response  = await resourseService.postPdfFile(postData);  
            console.log('Post Successful:');                  
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };  
    
}

const groupStore = new GroupStore();
export default groupStore;