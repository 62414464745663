import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import './EventsPage.css'; // Import CSS file for styling

const EventsPage = () => {
  // Sample data for events
  const events = [
    {
      id: 1,
      title: '秋季单身营：如何管理情绪？',
      time: '09:00 AM - 11:00 AM 11/02/2024 EST ',
      location: 'Zoom Meeting,Meeting ID：6369711781    Passcode： 123',
      type: 'Lecture and Discussion ',
      organizer: '刘爱俭师母'
    },
    {
      id: 2,
      title: 'Event 2',
      time: '2:00 PM - 4:00 PM',
      location: 'On-site',
      type: 'Lecture',
      organizer: 'Organizer 2'
    },
    {
        id: 3,
        title: 'Event 3',
        time: '2:00 PM - 4:00 PM',
        location: 'On-site',
        type: 'Lecture',
        organizer: 'Organizer 3'
      },

      {
        id: 4,
        title: 'Event 4',
        time: '2:00 PM - 4:00 PM',
        location: 'On-site',
        type: 'Lecture',
        organizer: 'Organizer 4'
      },
    // Add more events as needed
  ];

  // Chunk the events array into arrays of 3 events each
  const chunkedEvents = [];
  for (let i = 0; i < events.length; i += 3) {
    chunkedEvents.push(events.slice(i, i + 3));
  }

  return (
    <div className="events-container">
      <h1 className="page-title">Events</h1>
      {chunkedEvents.map((row, index) => (
        <div key={index} className="events-row">
          {row.map(event => (
            <div key={event.id} className="event-card">
              <h2 className="event-title">{event.title}</h2>
              <p><strong>Time:</strong> {event.time}</p>
              <p><strong>Location:</strong> {event.location}</p>
              <p><strong>Type:</strong> {event.type}</p>
              <p><strong>Organizer:</strong> {event.organizer}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EventsPage;
