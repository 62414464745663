import axios from "axios";
import authHeader from "./auth-header";

//const API_PUB_URL = 'http://localhost:8090/api/pub/';
//const API_RES_URL = 'http://localhost:8090/api/res/';

//const API_PUB_URL = 'http://34.130.190.39/res/api/pub/';
//const API_RES_URL = 'http://34.130.190.39/res/api/res/';

const baseURL = process.env.REACT_APP_API_BASE_URL_RES;

const API_RES_URL = baseURL + "/api/res/" ;
const API_PUB_URL = baseURL + "/api/pub/"; 



const getGroupList = async ()=>{
  return await axios.get(API_PUB_URL + "groups",  { headers: authHeader() });
}


const getGroupListById = async ()=>{
  return await axios.get(API_RES_URL + "groups",  { headers: authHeader() });
}

const getGroupById = async (id)=>{
  return await axios.get(API_RES_URL + "group/"+id,  { headers: authHeader() });
}


const getMyGroupList = async ()=>{
  return await axios.get(API_RES_URL + "mygroups",  { headers: authHeader() });
}

const getMyGroupMemberList = async (id)=>{
  return await axios.get(API_RES_URL + "mygroupsmember/"+id,  { headers: authHeader() });
}

const saveNewGroup = (data) => {
  return axios.post(API_RES_URL + "addgroup",
  {
    groupId : data.groupId,
    groupName : data.groupName,
    descb: data.descb,
    groupType: data.groupType,
    location : data.location,
    meetDateStr : data.time,
    organizer : data.organizer,
    status: data.status,
  } ,    
  { headers: authHeader() });
};

const saveUserGroup = (data) => {
  return axios.post(API_RES_URL + "addtogroup",
  {
    groupId : data.groupId,
  } ,    
  { headers: authHeader() });
};


const GroupService = {
  getGroupList,
  getGroupListById,
  getGroupById,
  getMyGroupList,
  saveNewGroup,
  saveUserGroup,
  getMyGroupMemberList,
  
};

export default GroupService;
