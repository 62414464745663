import React, {useEffect, useState } from "react";
import "./PostBoard.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import postBoardStore from "./PostBoardStore";
import { observer } from 'mobx-react';

const Post =observer( ({ post, depth = 0 }) => {
  const [showModal, setShowModal] = useState(false);
  const [responseContent, setResponseContent] = useState("");
  const [refId, setRefId] = useState(null);
  const [showResponses, setShowResponses] = useState(false); // State to track visibility of responses


  const toggleResponses = () => {
    setShowResponses(!showResponses);
  };

  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    
    const formData = {'text': responseContent, 'title' : 're', 'refid' : refId};

    console.log('Form data:', formData);
    await postBoardStore.postPostData(formData);
    await postBoardStore.fetchPostVwData(); 
    setShowModal(false);
  };

  const renderResponses = (responses, depth) => {
    if (!responses || responses.length === 0) {
      return null;
    }

    return (
      <div className="responses" style={{ marginLeft: `${5 * depth}px` }}>
        {responses.map((response, index) => (
          <div key={index} className="response-container">
            <div className="response">
              <p>{response.content}</p>
              <p>发布者: {response.author} 发布时间： {response.lastacess} </p>

              <button
                className="response-button"
                onClick={() =>{ setShowModal(true); setRefId(response.id)}}
              >
                回复                 
              </button>
            </div>
            {renderResponses(response.responses, depth + 1)}
          </div>
        ))}
      </div>
    );
  };
   
  return (
    <div className="message">
      <p>主题: {post.title}</p>
      <p>内容：{post.content}</p>
      <p>发布者: {post.author} 发布时间： {post.lastacess} </p>
      <button className="response-button" onClick={() => {setShowModal(true); setRefId(post.id)}}>
        回复 
      </button>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>添加回复</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="response-form" onSubmit={handleResponseSubmit}>
            <textarea
              rows="4" // Adjust the number of rows to make the textarea taller
              cols="50" // Adjust the number of columns to make the textarea wider
              value={responseContent}
              onChange={(e) => setResponseContent(e.target.value)}
              placeholder="Your response"
            /><br></br><br></br>
            <button type="submit">提交</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            关闭
          </Button>
          {/* Save button or other actions */}
        </Modal.Footer>
      </Modal>
      <button onClick={toggleResponses}>
        {showResponses ? '收起' : '展开'}
      </button>
      {showResponses && renderResponses(post.responses, depth)}
    </div>
  );
});

const PostBoard = observer(() => {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Trigger the API call to fetch data when the component mounts
    postBoardStore.fetchPostVwData();
    //setPosts(d.data);
}, []); // Empty dependency array ensures it runs only once on mount

useEffect(() => {
  if (postBoardStore.data && postBoardStore.data.data) {
    setPosts(postBoardStore.data.data);
  }
}, [postBoardStore.data]);

const { data, loading, error } = postBoardStore;
//setPosts(data.data);
 //console.log('???????????' + JSON.stringify(data.data));
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState([]);
  const [title, setTitle] = useState([]);
  
  const handlePostSubmit = async (e) => {

   
    e.preventDefault();

    console.log('Form post:', content);
    //console.log('Form id:', id);
    // var formData = new FormData();
    // formData.append('text', post);
    // formData.append('topic', "ttttppppiiic");
    const formData = {'text': content, 'title' : title};

    console.log('Form data:', formData);
    await postBoardStore.postPostData(formData);
    await postBoardStore.fetchPostVwData(); 
   // async
   // if (!post) return;
   // onAddResponse(responseContent, post.author);
   // setResponseContent("");
    setShowModal(false);
    
  };

  // Pagination variables
  const itemsPerPage = 5;
  const totalPages = Math.ceil(posts.length / itemsPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = posts.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination click
  const handlePaginationClick = (pageNumber) => {
      setCurrentPage(pageNumber);
  };



  return (
    <div className="post-board">
      <h1>留言簿</h1>
      <button className="response-button" onClick={() => setShowModal(true)}>
        新留言
      </button>
      {currentItems.map((post, index) => (
        <Post key={index} post={post} />
      ))}

      {/* Pagination */}
      <Row className="justify-content-center">
                <Col>
                    <Pagination>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePaginationClick(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            </Row>

    <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>新留言</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="response-form" onSubmit={handlePostSubmit}>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Subject of your topic"
              required 
            /><br></br>
            <textarea
              rows="4" // Adjust the number of rows to make the textarea taller
              cols="50" // Adjust the number of columns to make the textarea wider
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Write your message"
              required 
            />
            <br></br>
            <button type="submit">Submit</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          {/* Save button or other actions */}
        </Modal.Footer>
      </Modal>
    </div>    
  );
});

export default PostBoard;
