import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="contact-info">
                <p>Contact us: info@tristrands.org</p>
                <p>123 Main Street, Cityville, Country</p>
            </div>
            <div className="social-links">
                {/* Social media icons */}
            </div>
            <div className="copyright">
                <p>&copy; 2024 tristrands.org. 版权所有.</p>
            </div>
        </footer>
    );
}

export default Footer;
