import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Testimonials = () => {
    return (
        <section className="testimonials">
            <Container>
                <h2>成功见证</h2>
                <Row>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                见证：还记得去年参加教会2023冬令营许愿活动时，我写的是希望找到属灵的另一半。真的很奇妙，感谢神通过爱俭师母把我和我的男朋友连接在一起，也感谢有机会和他能一起学习刘师母的三股绳，从相识到相知，在主里彼此学习，彼此成长。现在我们也即将进入婚姻的殿堂，感谢刘师母的用心辅导，也感谢神一切的带领，让我们知道凡事都有定时，都在神的手中。希望我们在以后的我岁月里能继续相互扶持，相互包容，理解，和爱。荣耀归神，也愿神纪念刘师母所做的工，赐福她一家平安喜乐！
                                </Card.Text>
                                <Card.Title>anonymous</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                    
                                    感谢主的恩典和带领，也感谢爱俭师母的恋爱、婚姻辅导。在爱俭师母的三股绳学习中，我们不断成长，学会了在基督里的彼此相爱和包容，最终与我的爱人在神的恩典中迈入婚姻的殿堂。感谢神的奇妙安排与祝福，也感恩爱俭师母的悉心指导。
                                </Card.Text>
                                <Card.Title>anonymous</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                祝师母全家新年快乐! 愿神大大赐福你们全家，赐福你和牧师的事工。 你们爱神爱人，帮助了多少人，是我的榜样。
我真是非常地感恩，感谢神对我们的恩典和怜悯，感谢牧师和师母的帮助。
好几次我跟着YouTube 唱 "感谢神"，想到我的经历，想到神在我们家做的工，我的心被大大地触动，眼泪止不住哗哗落下。
这孩子的康复比我预期的要好的太多了，孩子今天的状态我几年前想都不敢想。除了感谢主还是感谢主!
师母你代祷信里写的对我是一个激励，我会很高兴如果我们的见证能够帮助到任何人。在我和丈夫的关系上我们还要继续努力。
                                </Card.Text>
                                <Card.Title>anonymous</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Text>
                                特别感谢神的是在我们正需要婚姻辅导的时候，神就把牧师师母从德州带了过来。一开始我们还不熟悉，师母就用她的视频和书让我们了解她的经历和她对神话语的理解，让我们看到神在她身上的作为和托付。慢慢我们开始敞开分享我们交往过程中的问题，师母就用她的身边人的经历来类比我们的问题，让我们看到把永远把神摆在我们关系中第一位的重要性。最后在我们进入婚姻以后，师母还有“售后服务“，告诉我们不是进入婚姻就没问题了，而是要把爱连上基督的爱，这样才总能活出无条件的爱。婚后每当我们有问题，师母的电话也变成了我们的“热线”。特别感恩神有师母在我们身边“保驾护航”，让我们更加认识婚姻的意义和在家庭生活中操练舍己。                                </Card.Text>
                                <Card.Title>anonymous</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Testimonials;
