import React from 'react';
import './AboutPage.css'; // Import CSS file for styling

const AboutPage = () => {
  return (
   <div className="about-page">
      <h1 className="about-title">关于我们</h1>
      <br></br>
      <p className="about-description"><b>“ 有人攻胜孤身一人，若有二人便能抵挡他， 三股合成的绳子不容易折断”。 （《传道书》4 章12节) </b>。三股绳应用在家庭关系中，一根代表丈夫，一根代表妻子，第三根代表上帝的同在。唯有夫妻都与上帝亲近，听从上帝的话语，才能够夫妻恩爱，共同走在蒙福的路上。

在现今社会，离婚率高升，恐婚症盛行。如何让大家认识到爱情的本质和婚姻的意义，从而能过上幸福的生活呢？ “使单身的有家，使有家的幸福，使孩子拥有完整的家”便成为我们的目的和使命！

为了给单身的朋友们创造一个互相认识的渠道，我们开通了三股绳单身网。 愿大家在这里找到你的真爱！通过学习如何交友恋爱，如何经营婚姻 可以使大家能够享受“永恒的爱”带给你的幸福人生！</p>

<br></br><p align="right">--- 三股绳婚姻家庭中心</p>
      
  </div>

  );
 
};

export default AboutPage;
