import React, { useState } from "react";
import { observer } from 'mobx-react';

const PDFViewer = ({src: pdfUrl}) => {
    
    //const [pdfUrl, setPdfUrl] = useState(null); 
    
    return (
        <div>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              title="PDF Viewer"
              width="100%"
              height="600px"
            ></iframe>
          )}
        </div>
      );

}

export default PDFViewer